<template>
  <div>

    <div class="global-container rtl">
      <div class="card login-form">
        <div class="card-body">
          <h3 class="card-title text-center">seenstore.io</h3>
          <div class="card-text text-left">
            <form @submit.prevent="login">
              <div class="form-group">
                <label for="exampleInputEmail1">ناوی بەکارهێنەر</label>
                <input v-model="user_name" type="text" class="form-control form-control-sm" id="exampleInputEmail1"
                  aria-describedby="emailHelp">
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">وشەی نهێنی</label>
                <input v-model="user_password" type="password" class="form-control form-control-sm"
                  id="exampleInputPassword1">
              </div>
              <div  v-if="message" class="alert alert-danger" role="alert">
                <strong>{{message}}</strong>
              </div>
              <button type="submit" class="btn btn-primary btn-block">چونەژورەوە</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        message: '',
        user_name: '',
        user_password: ''
      }
    },
    methods: {
      login() {
        let params = {
          username: this.user_name,
          password: this.user_password
        }
        axios.post('admin/auth.php', params)
          .then(r => {
            console.log(r);
            if (r.data.isAuth == 'true') {
              this.$store.state.auth.isAuth = true
              this.$store.state.auth.user = r.data.user
              localStorage.setItem('user', JSON.stringify(r.data.user))
              this.$router.push({
                path: '/'
              })
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            } else {
              this.message = 'ناوی بەکارهێنەر یان وشەی نهێنی هەڵەیە'
            }
          })
          .catch(e => {
            this.message = e?.message
          })
      }
    },
  }
</script>
<style>
  .global-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #f5f5f5; */
  }

  form {
    padding-top: 10px;
    font-size: 14px;
    margin-top: 30px;
  }

  .card-title {
    font-weight: 300;
  }

  .btn {
    font-size: 14px;
    margin-top: 20px;
  }


  .login-form {
    width: 330px;
    margin-top: 200px;
  }

  .sign-up {
    text-align: center;
    padding: 20px 0 0;
  }
</style>